import React, { ReactNode } from 'react'
import classNames from 'classnames'

import { Button as AntdButton } from 'antd'
import { ButtonProps as AntdButtonProps } from 'antd/lib/button'

import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'

import { Modify } from 'helpers/typings'

import { IntlMessageKeys } from 'components/FormattedMessage/types'

import './index.less'

const Button: React.FC<ButtonProps> = (props) => {
  const {
    children,
    className,
    icon,
    type = 'default',
    size = 'default',
    translationKey,
    loading,
    disabled,
    ...restProps
  } = props

  const prefix = loading ? (
    <Icon name="refresh" className="ant-btn__loading icon-spin" />
  ) : icon

  return (
    <AntdButton
      className={classNames(className, {
        'ant-btn--x-small': size === 'x-small',
      })}
      size={size === 'x-small' ? 'small' : size}
      disabled={disabled || loading}
      // we're overriding button types but antd button doesn't support it, that's why we have to use 'as' here
      // because the prop is optional but it's value can't be undefined! we had to exclude undefined from the type
      type={type as Exclude<AntdButtonProps['type'], undefined>}
      {...restProps}
    >
      {prefix}

      {children}

      {translationKey && <FormattedMessage id={translationKey} />}
    </AntdButton>
  )
}

export interface ButtonProps extends Modify<AntdButtonProps, {
  size?: ButtonSize,
  icon?: ReactNode,
  type?: ButtonType
}> {
  children?: ReactNode,
  className?: string,
  loading?: boolean,
  disabled?: boolean,
  translationKey?: IntlMessageKeys,
}

type ButtonType = AntdButtonProps['type']
  | 'action-bordered'
  | 'business'
  | 'danger-bordered'
  | 'primary-bordered'
  | 'inline-danger'
  | 'inline'

type ButtonSize = 'default' | 'large' | 'small' | 'x-small'

export default Button
