import { FieldReadFunction } from '@apollo/client'
import { RemoteLineItem } from 'components/OfferForm/types'
import { CategoryEnum } from 'generated/types'

type Costs = { [key in CategoryEnum]?: number } & { total?: number | null }

export const generateCostsFromLineItems: FieldReadFunction = (_existing, { readField }) => {
  const items = readField('items') as Array<RemoteLineItem>

  if (!items) {
    return {}
  }

  return items.filter(item => !item.optional).reduce((costs: Costs, item: RemoteLineItem) => {
    const category = readField('category', item) as CategoryEnum
    const price = readField('price', item)
    const quantity = readField('quantity', item)

    if (!category || !price || !quantity) {
      console.warn('Required information are missing: category, price and quantity props are required')

      return {}
    }

    const total = (price ? Number(price) : 0) * (quantity ? Number(quantity) : 0)

    costs[category] = (costs[category] || 0) + total
    costs.total = (costs.total || 0) + total

    return costs
  }, { total: 0 })
}
