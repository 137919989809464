/** @module helpers/nextjsApiCall */

import axios from 'axios'
import get from 'lodash/get'
import qs from 'qs'
import parseUrl from 'parseurl'
import { captureException } from 'helpers/sentry'
import getConfig from 'next/config'
import {
  compactObject,
  getLocationOrigin,
  isServer,
  serverSideRedirect,
} from './utilities'

import { addQueryStringParamsToUrl, buildQueryString } from './params'

import { actionCreators as headerDataActions } from 'store/modules/HeaderData'
import { actionCreators as currentUserActions } from 'store/modules/CurrentUser'

let liveRequestsCancelPool = []

export const nextjsApiConfig = (req, additionalHeaders = {}) => {
  if (!req) {
    return {
      cancelToken: new axios.CancelToken((cancel) => { liveRequestsCancelPool.push(cancel) }),
    }
  }

  // see https://github.com/axios/axios#request-config

  const headers = compactObject({
    format: 'json',
    'x-forwarded-host': req.get('host').split(':')[0],
    cookie: req.get('cookie'),
    ...additionalHeaders,
  })

  return {
    headers,
    maxRedirects: 0,
  }
}

const cancelLiveRequests = () => {
  if (isServer()) {
    return false
  }

  liveRequestsCancelPool.forEach((cancel) => cancel())
  liveRequestsCancelPool = []
}

export const nextjsApiHost = () => {
  const { serverRuntimeConfig } = getConfig()

  return isServer() ? serverRuntimeConfig.backendUrl : getLocationOrigin()
}

export const nextjsGetPathAndQueryString = (asPath) => {
  const [pathWithQueryString] = asPath.split('#')

  const [path, queryString] = pathWithQueryString.split('?')

  const parsedQueryString = qs.parse(queryString)

  return {
    path,
    queryString: queryString ? `?${queryString}` : '',
    parsedQueryString,
  }
}

export const nextjsApiUrl = (req, asPath) => {
  const { path, queryString } = nextjsGetPathAndQueryString(asPath)

  const isHomePage = (path === '/')
  const jsonPath = isHomePage ? `${path}?format=json` : `${path}.json`
  const queryStringWithCorrectSeparator = isHomePage ? queryString.replace('?', '&') : queryString

  return `${nextjsApiHost()}${jsonPath}${queryString ? queryStringWithCorrectSeparator : ''}`
}

const initialApiCallSuccessActions = (data, reduxStore) => {
  reduxStore.dispatch(headerDataActions.setHeaderData(data))

  if (data.currentUser) {
    reduxStore.dispatch(currentUserActions.setCurrentUser(data.currentUser))
  }

  return data
}

const defaultRedirectTargetSanitizer = (req, redirectUrl) => {
  const urlObject = new URL(redirectUrl.toString())

  // add redirectPath to query string when user is being redirected to /login
  if (urlObject.pathname === '/login' && !urlObject.searchParams.get('redirectPath')) {
    const queryParams = {
      redirectPath: parseUrl(req).pathname,
    }

    redirectUrl = addQueryStringParamsToUrl(urlObject, queryParams)
  }

  // prevent redirecting to /header
  // normally happens when session has timed out
  if (urlObject.pathname === '/header') {
    redirectUrl = parseUrl(req).path
  }

  return redirectUrl
}

/**
 * Greps JSON data from our Rails backend.
 * Dependent on the status code we've got from the Rails backend various actions are triggered:
 * - 301, 302 - the redirect is forwarded, target can be changed using the `redirectTargetSanitizer` option
 * - 401 - we redirect to `/login`
 *
 * @function
 * @param {Object} req - the request object from NextJS
 * @param {Object} res - the response object from NextJS
 * @param {string} path - the requested path
 * @param {Object} reduxStore - the Redux store object
 * @param {Object} [options]
 * @param {Function} options.redirectTargetSanitizer - chaning the redirections URL Object before the redirect
 * @returns {Object} response - including data we got from the Rails backend
 * @returns {Boolean} response.status - if the call was successful
 * @returns {Object} [response._headers] - headers we got from Rails backend
 */
export const initialApiCall = async (req, res, path, reduxStore, options = {}) => {
  cancelLiveRequests()

  axios.defaults.headers.common.Accept = 'application/json'
  if (req) {
    axios.defaults.headers.common.X_FORWARDED_PROTO = req.headers['x-forwarded-proto']
  }

  const apiResult = await axios.get(nextjsApiUrl(req, path), nextjsApiConfig(req))
    .then((response) => {
      return {
        success: true,
        ...response.data,
        _headers: response.headers,
      }
    })
    .catch((error) => {
      if (error.toString().toLowerCase() === 'cancel') {
        return {}
      }

      if (res) {
        const responseHttpStatusCode = get(error, ['request', 'res', 'statusCode'], 500)

        if ([301, 302].includes(responseHttpStatusCode)) {
          let redirectTarget = new URL(error.request.res.headers.location)

          redirectTarget.pathname = redirectTarget.pathname.replace(/\.json$/, '')

          if (options.redirectTargetSanitizer) {
            redirectTarget = options.redirectTargetSanitizer(redirectTarget)
          }

          redirectTarget = defaultRedirectTargetSanitizer(req, redirectTarget.toString())

          serverSideRedirect(
            res,
            redirectTarget.toString(),
            { 'Set-Cookie': error.request.res.headers['set-cookie'] },
            error.request.res.statusCode,
          )

          return { success: false }
        }

        if (responseHttpStatusCode === 401) {
          serverSideRedirect(
            res,
            '/login',
            { 'Set-Cookie': error.request.res.headers['set-cookie'] },
          )

          return { success: false }
        }

        if ([403, 404].includes(responseHttpStatusCode) && error.response.data) {
          res.statusCode = responseHttpStatusCode

          return {
            ...initialApiCallSuccessActions(error.response.data, reduxStore),
            statusCode: responseHttpStatusCode,
          }
        }

        // don't log not found errors
        if (responseHttpStatusCode !== 404) {
          captureException(error, { req, res, path })
        }

        res.statusCode = get(error, ['response', 'status'], 500)

        return {
          ...get(error, ['response', 'data'], {}),
          statusCode: responseHttpStatusCode,
          success: false,
        }
      }
    })

  return apiResult?.success ? initialApiCallSuccessActions(apiResult, reduxStore) : apiResult
}

export const initialHeaderRequest = async (pageProps) => {
  const { reduxStore, req, res, query, asPath } = pageProps

  const { parsedQueryString, path } = nextjsGetPathAndQueryString(asPath)

  const newQuery = {
    ...parsedQueryString,
    requested_path: path,
  }

  const headerResult = await initialApiCall(req, res, `/header?${buildQueryString(newQuery)}`, reduxStore)

  // remove authentication_token from path when authenticated
  if (headerResult.success && query.authentication_token) {
    const pathWithoutAuthenticationToken = addQueryStringParamsToUrl(
      parseUrl(req),
      { authentication_token: null },
      { removeEmptyParams: true },
    )

    serverSideRedirect(res, pathWithoutAuthenticationToken, headerResult._headers)
  }

  return headerResult
}
